import "./App.css";
import "../Authorization/Authorization";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { config } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Authorization from "../Authorization/Authorization";
import Registration from "../Registration/Registration";
import AuthorizationForgetPassword from "../AuthorizationForgetPassword/AuthorizationForgetPassword";
import AuthorizationSetPassword from "../AuthorizationSetPassword/AuthorizationSetPassword";
import VotesPage from "../VotesPage/VotesPage";
import MainPage from "../MainPage/MainPage";
import CallVotingPage from "../CallVotingPage/CallVotingPage";
import MyProfilePage from "../MyProfilePage/MyProfilePage";
import DetailsVotesPage from "../DetailsVotesPage/DetailsVotesPage";
import VotesPageSuccessRegLaterModal from "../VotesPageSuccessRegLaterModal/VotesPageSuccessRegLaterModal";
import PreLoaderCallVotingPage from "../PreLoaderCallVotingPage/PreLoaderCallVotingPage";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import timeZone from "../../utils/TimeZoneData/TimeZoneRu.json";
import * as Auth from "../../Api/Auth";
import * as Events from "../../Api/Events";
import { NoMatchRoutes } from "../../utils/NoMatchRoutes/NoMatchRoutes";
import { Stomp } from "@stomp/stompjs";
import { useLoginByCode } from "../../hooks/useLoginByCode";

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [authError, setAuthError] = useState("");
  const [userName, setUserName] = useState("");
  const [isPolicyAccept, setPolicyAccept] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [changeBorderInputEmail, setChangeBorderInputEmail] = useState(
    "_input-border-black-reg-page"
  );
  const [hideRegForm, setHideRegForm] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [isSuccessModalActive, setSuccessModalActive] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [isResultTabOpen, setResultTabOpen] = useState(false);
  const [utcOffset, setUtcOffset] = useState("");
  const [changeUtcOffset, setChangeUtcOffset] = useState("");
  const [preLoaderAuthBtn, setPreloaderAuthBtn] = useState(false);
  const [preLoaderRegBtn, setPreLoaderRegBtn] = useState(false);
  const [joinId, setJoinId] = useState("");
  const [isReloadDetailsPage, setReloadDetailsPage] = useState(false);
  const [eventWaitingIdByLink, setEventWaitingIdByLink] = useState("");
  const [eventQuestionsIdByLink, setEventQuestionsIdByLink] = useState("");
  const [eventResultIdByLink, setEventResultIdByLink] = useState("");
  const [isReloadPage, setReloadPage] = useState(false);
  const [isSignedEventId, setSignedEventId] = useState("");
  const [isPreloaderActive, setPreloaderActive] = useState(false);

  const [newEventId, setNewEventId] = useState("");

  const [statsReload, setStatsReload] = useState(false);
  const [loginByCode, setLoginByCode] = useState(false);

  console.log("pathname: ", pathname);

  function requestHelper(request, body = {}) {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("accessToken")) {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        request(accessToken, body)
          .then((res) => {
            if (res.status && res.status === "failure") {
              console.error("failure in request helper");
              // return;
              Auth.login({ type: "refresh-token", token: refreshToken })
                .then((newTokens) => {
                  if (!newTokens.ok) {
                    console.error("ошибка при получении refresh-token");
                    return;
                    setTimeout(() => {
                      window.location.reload();
                    }, 10000);
                    return;
                    logout();
                  } else {
                    // // const { accessToken, refreshToken } = JSON.stringify(newTokens);
                    // console.log("new tokens: ", newTokens.json());
                    // localStorage.setItem("accessToken", accessToken);
                    // localStorage.setItem("refreshToken", refreshToken);

                    request(accessToken, body)
                      .then((res) => {
                        resolve(res);
                      })
                      .catch((err) => {
                        throw new Error(err.message);
                      });
                  }
                })
                .catch((err) => {
                  if (err.message) {
                    throw new Error(err.message);
                  }
                });
            } else {
              resolve(res);
            }
          })
          .catch((err) => {
            disactivatePreloader();
            if (err.message) {
              throw new Error(err.message);
            }
          });
      } else {
        logout();
      }
    });
  }

  // requestHelper(Events.getEvents)

  // useLoginByCode();

  const location = useLocation();

  // console.log("location: ", location);

  const loginByCodeHandler = async (code) => {
    console.log("login by code handler");
    const loginResponse = await Auth.login({
      token: code,
      type: `authorization-token`,
    });

    if (loginResponse?.status === 400) {
      setAuthError("Пользователь не найден");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status === 401) {
      setAuthError("Неверный логин или пароль");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status) {
      setAuthError(`Ошибка сервера: ${loginResponse.status}`);
      return;
    }

    const userData = await Auth.getMe(loginResponse.accessToken);

    if (loginResponse.accessToken && loginResponse.refreshToken) {
      localStorage.setItem("accessToken", loginResponse.accessToken);
      localStorage.setItem("refreshToken", loginResponse.refreshToken);
    }

    localStorage.setItem("user", JSON.stringify(userData));
    setLoggedIn(true);
    addCurrentUser(userData);
    createUserName(userData);
    setOffset(userData.utcOffset);

    navigate(location.pathname);

    // if (joinId) {
    //   joinEvent(joinId);
    //   navigate("/");
    //   return;
    // }

    // if (eventWaitingIdByLink) {
    //   handleCurrentEvents({ id: eventWaitingIdByLink }, true);
    //   return;
    // }

    // if (eventQuestionsIdByLink) {
    //   const data = { id: eventQuestionsIdByLink };
    //   requestHelper(Events.getEvent, data)
    //     .then((res) => {
    //       // тут вопросик мб не правильная проверка в конце
    //       handleCurrentEvents(
    //         data,
    //         res.status === "ended" ||
    //           res.status === "quorum_unpresant" ||
    //           !res.isRegistered
    //       );
    //     })
    //     .catch((err) => {
    //       throw new Error(err?.message || "Неизвестная ошибка");
    //     });
    //   return;
    // }

    // if (eventResultIdByLink) {
    //   showEventResult({ id: eventResultIdByLink });
    //   return;
    // }
    // navigate("/");

    // navigate(location.pathname);
  };

  const code =
    location.search.includes("?code=") && !location.pathname.includes("rstpwd")
      ? location.search.replace("?code=", "")
      : null;

  useEffect(() => {
    if (!isLoggedIn && code) {
      setLoginByCode(true);
      loginByCodeHandler(code);

      const currentEvent = {
        id: location.pathname.replace("/details-vote/", ""),
      };

      if (localStorage.getItem("currentEvent")) {
        localStorage.removeItem("currentEvent");
        localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
      } else {
        localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
      }

      //navigate(`../details-vote/${currentEvent.id}`, { replace: true });

      // navigate("my-profile");
    }
  }, []);

  useEffect(() => {
    if (pathname === "/auth" && isLoggedIn && !loginByCode) {
      navigate("/");
      window.scrollTo(0, 0);
    } else if (pathname === "/auth" && !isLoggedIn && !loginByCode) {
      navigate("/auth");
    }
  }, [pathname, isLoggedIn, navigate]);

  function handleReloadPage() {
    setReloadPage(false);
  }

  function handleCleanIsSignedEventId() {
    setSignedEventId("");
  }

  function activatePreloader() {
    setPreloaderActive(true);
  }

  function disactivatePreloader() {
    setPreloaderActive(false);
  }

  // useEffect(() => {
  //     if (pathname === '/auth' && isPreloaderActive === true) {
  //         setTimeout( () => {
  //             window.location.reload()
  //         }, 10000);
  //         disactivatePreloader()
  //     }
  // }, [pathname, isPreloaderActive])

  useEffect(() => {
    if (isReloadPage) {
      requestHelper(Events.getEvents)
        .then((data) => {
          if (data.status === "failure") {
            setAllEvents([]);
          } else {
            setAllEvents(data);
            if (data.length !== allEvents.length) {
              handleShowSuccessModal();
              console.log("Вы были добавлены в новое голосование!");
              setSuccessModalText("Вы были добавлены в новое голосование!");
              setNewEventId(data[data.length - 1].id);
              setStatsReload(true); // чтобы скачать заново
            }

            handleReloadPage();
            data.forEach((event) => {
              if (event.status !== "ended") {
                if (event.status !== "quorum_unpresant") {
                  subscribeToEvent(event.id, config);
                }
              }
            });
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
    }
    // eslint-disable-next-line
  }, [isReloadPage]);

  function initStompClient(ws_connect) {
    let client = Stomp.client(ws_connect);
    client.debug = function () {};
    return client;
  }

  function handleMessage(message) {
    setReloadPage(true);
    setSignedEventId(message.body);
  }

  function handleMessageDelete(message) {
    window.location.reload();
  }

  function subscribeToEvent(eventId, config) {
    const client = initStompClient(config.ws_connect);
    const on_connect = function (x) {
      client.subscribe(`/exchange/${eventId}/time`, handleMessage, {});
      client.subscribe(`/exchange/${eventId}/delete`, handleMessageDelete, {});
      client.subscribe(
        `/exchange/${eventId}/event_statistics`,
        handleMessage,
        {}
      );
    };

    const on_error = function (err, tmp) {};

    client.connect(config.ws_user, config.ws_pass, on_connect, on_error, "/");
    client.onWebSocketClose = function (evt) {};
  }

  function subscribeToNewEvents(userId, config) {
    console.log("userId: ", userId);
    const client = initStompClient(config.ws_connect);
    const on_connect = function (x) {
      client.subscribe(`/exchange/events/${userId}`, handleMessage, {});
    };

    const on_error = function (err, tmp) {};

    client.connect(config.ws_user, config.ws_pass, on_connect, on_error, "/");
    client.onWebSocketClose = function (evt) {};
  }

  function firstSubscribeToEvents(events) {
    if (events.length !== 0) {
      events.forEach((event) => {
        if (event.status !== "ended") {
          if (event.status !== "quorum_unpresant") {
            subscribeToEvent(event.id, config);
          }
        }
      });
    }
  }

  function joinEvent(id) {
    const body = {
      id: id,
    };
    requestHelper(Events.joinEventByLink, body)
      .then((data) => {
        if (data.status === "ok") {
          setJoinId("");
          requestHelper(Events.getEvents)
            .then((data) => {
              if (data.status === "failure") {
                setAllEvents([]);
              } else {
                setAllEvents(data);
                firstSubscribeToEvents(data);
                handleShowSuccessModal();
                setSuccessModalText("Вы были успешно добавлены к голосованию");
                handleCurrentEvents(body, true);
              }
            })
            .catch((err) => {
              throw new Error(err?.message || "Неизвестная ошибка");
            });
        } else if (data.status === "failure") {
          setJoinId("");

          setPreloaderActive(false);
          handleShowSuccessModal();
          setSuccessModalText("Достигнут лимит участников голосования");

          if (data.text.includes("Not found")) {
            setTimeout(() => {
              window.location.reload();
            }, 10000);
          }
          if (data.text === "User has already joined") {
            setPreloaderActive(false);
            handleShowSuccessModal();
            setSuccessModalText("Вы уже присоединились к данному голосованию");
            handleCurrentEvents(body, true);
          } else if (data.text === "Registration is over") {
            setPreloaderActive(false);
            requestHelper(Events.getEvents)
              .then((data) => {
                const foundedEvent = data.find((event) => event.id === id);
                if (foundedEvent !== undefined) {
                  handleShowSuccessModal();
                  setSuccessModalText(
                    "Вы уже присоединились к данному голосованию"
                  );
                  handleCurrentEvents(body, true);
                } else {
                  handleShowSuccessModal();
                  setSuccessModalText(
                    "Вы не можете быть добавлены, так как регистрация завершена или событие окончено"
                  );
                }
              })
              .catch((err) => {
                throw new Error(err?.message || "Неизвестная ошибка");
              });
          }
        }
      })
      .catch((err) => {
        throw new Error(err?.message || "Неизвестная ошибка");
      });
    setPreloaderActive(true);
  }

  useEffect(() => {
    const url = window.location.href.split("/");
    if (isLoggedIn) {
      if (
        pathname === "/" ||
        pathname === "/votes-page" ||
        pathname === "/my-profile" ||
        pathname.includes("/details-vote")
      ) {
        requestHelper(Events.getEvents)
          .then((data) => {
            if (data.status === "failure") {
              setAllEvents([]);
            } else {
              setAllEvents(data);
              firstSubscribeToEvents(data);
            }
          })
          .catch((err) => {
            throw new Error(err?.message || "Неизвестная ошибка");
          });
      } else if (url[3] === "join") {
        if (url[4] !== undefined) {
          joinEvent(url[4]);
          navigate("/");
        } else {
          navigate("/");
        }
      } else if (url[3] === "waiting") {
        if (url[4] !== undefined) {
          const data = {
            id: url[4],
          };
          handleCurrentEvents(data, true);
        } else {
          navigate("/");
        }
      } else if (url[3] === "questions") {
        if (url[4] !== undefined) {
          const data = {
            id: url[4],
          };
          requestHelper(Events.getEvent, data)
            .then((res) => {
              if (res.status === "ended" || res.status === "quorum_unpresant") {
                handleCurrentEvents(data, true);
              } else {
                if (res.isRegistered) {
                  handleCurrentEvents(data, false);
                } else {
                  handleCurrentEvents(data, true);
                }
              }
            })
            .catch((err) => {
              throw new Error(err?.message || "Неизвестная ошибка");
            });
        } else {
          navigate("/");
        }
      } else if (url[3] === "results") {
        if (url[4] !== undefined) {
          const data = {
            id: url[4],
          };
          showEventResult(data);
        } else {
          navigate("/");
        }
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  function hideRegisterModal() {
    setModalActive(false);
    setPreLoaderRegBtn(false);
  }

  function handlePolicyAccept() {
    if (isPolicyAccept) {
      setPolicyAccept(false);
    } else {
      setPolicyAccept(true);
    }
  }

  const userDefaultName = {
    lastName: "Неизвестный",
    firstName: "Пользователь",
  };

  function createUserName(user) {
    const firstName = function () {
      if (!user.firstName) {
        return `${userDefaultName.firstName.charAt(0)}`;
      } else {
        return `${user.firstName.charAt(0)}`;
      }
    };
    const lastName = function () {
      if (!user.lastName) {
        return userDefaultName.lastName;
      } else {
        return user.lastName;
      }
    };
    const middleName = function () {
      if (!user.secondName) {
        return "";
      } else {
        return `${user.secondName.charAt(0)}.`;
      }
    };
    const shortName = `${lastName()} ${firstName()}.${middleName()}`;
    setUserName(shortName);
  }

  function logout() {
    if (localStorage.getItem("user")) {
      localStorage.removeItem("user");
    }
    // if (localStorage.getItem("jwt")) {
    //   localStorage.removeItem("jwt");
    // }
    if (localStorage.getItem("accessToken")) {
      localStorage.removeItem("accessToken");
    }
    if (localStorage.getItem("refreshToken")) {
      localStorage.removeItem("refreshToken");
    }
    if (localStorage.getItem("currentEvent")) {
      localStorage.removeItem("currentEvent");
    }
    setLoggedIn(false);
    setCurrentUser({});
    setUtcOffset("");
    navigate("/auth");
    setPreloaderAuthBtn(false);
    setPreLoaderRegBtn(false);
  }

  function setOffset(utc) {
    if (utc !== undefined) {
      const localOffset = timeZone.find(
        (value) => value.VALUE === utc.toString()
      );
      setUtcOffset(localOffset.LABEL);
      setChangeUtcOffset(localOffset.VALUE);
    } else {
      console.log("");
    }
  }

  function handleLogin(email, password) {
    if (email === "" || password === "") {
      setAuthError("Необходмо заполнить все поля");
      setPreloaderAuthBtn(false);
    } else {
      Auth.authorize(email, password)
        .then((res) => {
          if (res.status === "failure") {
            setAuthError("Неправильное имя пользователя или пароль");
            setPreloaderAuthBtn(false);
          } else {
            // if (isRememberMe) {
            //   localStorage.setItem("user", JSON.stringify(res));
            // }
            setLoggedIn(true);
            addCurrentUser(res);
            createUserName(res);
            setOffset(res.utcOffset);
            const subUserId = `${res.authorities[0].role}${res.id}`;
            subscribeToNewEvents(subUserId, config);
            if (joinId !== "") {
              joinEvent(joinId);
              navigate("/");
            } else if (eventWaitingIdByLink !== "") {
              const data = {
                id: eventWaitingIdByLink,
              };
              handleCurrentEvents(data, true);
            } else if (eventQuestionsIdByLink !== "") {
              const data = {
                id: eventQuestionsIdByLink,
              };
              requestHelper(Events.getEvent, data)
                .then((res) => {
                  if (
                    res.status === "ended" ||
                    res.status === "quorum_unpresant"
                  ) {
                    handleCurrentEvents(data, true);
                  } else {
                    if (res.isRegistered) {
                      handleCurrentEvents(data, false);
                    } else {
                      handleCurrentEvents(data, true);
                    }
                  }
                })
                .catch((err) => {
                  throw new Error(err?.message || "Неизвестная ошибка");
                });
            } else if (eventResultIdByLink !== "") {
              const data = {
                id: eventResultIdByLink,
              };
              showEventResult(data);
            } else {
              navigate("/");
            }
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
      setPreloaderAuthBtn(true);
      setAuthError("");
    }
  }

  function handleLoginPhone(phone, phoneCode) {
    Auth.authorizePhoneCode(phone, phoneCode)
      .then((res) => {
        if (res.status === "failure") {
          setAuthError("Неверный код");
          setPreloaderAuthBtn(false);
        } else if (res.status === "Need registration") {
          setAuthError("Необходимо пройти регистрацию");
          setPreloaderAuthBtn(false);
        } else {
          // if (isRememberMe) {
          //   localStorage.setItem("user", JSON.stringify(res));
          // }
          setLoggedIn(true);
          addCurrentUser(res);
          createUserName(res);
          setOffset(res.utcOffset);
          const subUserId = `${res.authorities[0].role}${res.id}`;
          subscribeToNewEvents(subUserId, config);
          if (joinId !== "") {
            joinEvent(joinId);
            navigate("/");
          } else if (eventWaitingIdByLink !== "") {
            const data = {
              id: eventWaitingIdByLink,
            };
            handleCurrentEvents(data, true);
          } else if (eventQuestionsIdByLink !== "") {
            const data = {
              id: eventQuestionsIdByLink,
            };
            requestHelper(Events.getEvent, data)
              .then((res) => {
                if (
                  res.status === "ended" ||
                  res.status === "quorum_unpresant"
                ) {
                  handleCurrentEvents(data, true);
                } else {
                  if (res.isRegistered) {
                    handleCurrentEvents(data, false);
                  } else {
                    handleCurrentEvents(data, true);
                  }
                }
              })
              .catch((err) => {
                throw new Error(err?.message || "Неизвестная ошибка");
              });
          } else if (eventResultIdByLink !== "") {
            const data = {
              id: eventResultIdByLink,
            };
            showEventResult(data);
          } else {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        throw new Error(err?.message || "Неизвестная ошибка");
      });
    setPreloaderAuthBtn(true);
    setAuthError("");
  }

  function addCurrentUser(user) {
    setCurrentUser(user);
  }

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      addCurrentUser(user);
      createUserName(user);
      setPreloaderAuthBtn(false);
      setLoggedIn(true);
      setOffset(user.utcOffset);

      const subUserId = `${user.authorities[0].role}${user.id}`;
      subscribeToNewEvents(subUserId, config);
      if (
        pathname === "/auth" ||
        pathname.includes("/rstpwd") ||
        pathname.includes("/reset-password") ||
        pathname === "/registration" ||
        pathname === "/reg-second-page"
      ) {
        navigate("/");
      } else if (pathname === "/forget") {
        navigate("/my-profile");
      }
    } else {
      const url = window.location.href.split("/");

      if (
        pathname === "/" ||
        pathname === "/call-voting-page" ||
        pathname === "/my-profile" ||
        pathname.includes("/details-vote") ||
        pathname === "/result-vote" ||
        pathname === "/votes-page"
      ) {
        const code = location.search.includes("?code=");

        !code && logout();
      } else if (url[3] === "join") {
        if (url[4] !== undefined) {
          setJoinId(url[4]);
          logout();
        } else {
          logout();
        }
      } else if (url[3] === "waiting") {
        if (url[4] !== undefined) {
          setEventWaitingIdByLink(url[4]);
          logout();
        } else {
          logout();
        }
      } else if (url[3] === "questions") {
        if (url[4] !== undefined) {
          setEventQuestionsIdByLink(url[4]);
          logout();
        } else {
          logout();
        }
      } else if (url[3] === "results") {
        if (url[4] !== undefined) {
          setEventResultIdByLink(url[4]);
          logout();
        } else {
          logout();
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  function hideRegEmailErrors() {
    setEmailErrorMessage("");
    setChangeBorderInputEmail("_input-border-black-reg-page");
  }

  function handleRegister(registerData) {
    if (isPolicyAccept) {
      if (registerData.isRegistrationByToken) {
        Auth.registrationUserByToken(registerData)
          .then((res) => {
            if (res.status === "ok") {
              setModalActive(true);
              hideRegEmailErrors();
              setHideRegForm(true);
            }
          })
          .catch((err) => {
            //throw new Error(err?.message || "Неизвестная ошибка");
          });
      } else {
        Auth.registration(registerData)
          .then((res) => {
            console.log("res register: ", res);

            if (res && res.ok) {
              setEmailErrorMessage("");
              setModalActive(true);
              hideRegEmailErrors();
              setHideRegForm(true);
            } else {
              setChangeBorderInputEmail("_input-border-red");
              const data = res.json();
              setEmailErrorMessage(data.message || "Ошибка регистрации");
            }
          })
          .catch((err) => {
            console.log(err);
            //throw new Error(err?.message || "Неизвестная ошибка");
          });
      }
    } else {
      handleShowSuccessModal();
      setSuccessModalText("Необходимо отметить ознакомление с политикой");
    }
  }

  function handleReloadDetailsPage() {
    if (pathname.includes("/details-vote")) {
      if (isReloadDetailsPage) {
        setReloadDetailsPage(false);
      } else {
        setReloadDetailsPage(true);
      }
    }
  }

  const toggleEventRegistration = (eventId, isRegistered, skipRegistration) => {
    const body = {
      id: eventId,
    };
    if (skipRegistration) {
      requestHelper(Events.registrationUserInEvents, body)
        .then((data) => {
          if (data.status === "ok") {
            handleCurrentEvents(body, false);
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
    } else {
      requestHelper(Events.registrationUserInEvents, body)
        .then((data) => {
          if (data.status === "ok") {
            if (!isRegistered) {
              handleShowSuccessModal();
              setSuccessModalText("Вы успешно зарегистрировались!");
            } else {
              handleShowSuccessModal();
              setSuccessModalText("Вы успешно отменили регистрацию!");
            }
            handleReloadDetailsPage();
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
    }
  };

  function handleCurrentEvents(data, isDetailsClick) {
    const currentEvent = {
      id: data.id,
    };
    if (localStorage.getItem("currentEvent")) {
      localStorage.removeItem("currentEvent");
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    } else {
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    }
    if (isDetailsClick) {
      navigate(`/details-vote/${data.id}`);
      setEventWaitingIdByLink("");
      setEventQuestionsIdByLink("");
    } else {
      navigate("/call-voting-page");
      setEventQuestionsIdByLink("");
    }
  }

  function handleResultTabOpen() {
    setResultTabOpen(false);
  }

  function showEventResult(data) {
    const currentEvent = {
      id: data.id,
    };
    if (localStorage.getItem("currentEvent")) {
      localStorage.removeItem("currentEvent");
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    } else {
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    }
    setResultTabOpen(true);
    navigate(`/details-vote/${data.id}`);
    setEventResultIdByLink("");
  }

  function handleShowSuccessModal() {
    if (isSuccessModalActive) {
      setSuccessModalActive(false);
    } else {
      setSuccessModalActive(true);
    }
  }

  function formatDate(serverDate) {
    const localDate = new Date(serverDate.toString());
    const defaultDate = localDate.getDate();
    const date = `${
      defaultDate.toString().length === 1
        ? `${"0" + defaultDate}`
        : `${defaultDate}`
    }`;
    const defaultMonth = localDate.getMonth() + 1;
    const month = `${
      defaultMonth.toString().length === 1
        ? `${"0" + defaultMonth}`
        : `${defaultMonth}`
    }`;
    const year = localDate.getFullYear();
    return `${date + "." + month + "." + year}`;
  }

  function formatTime(serverDate) {
    const localDate = new Date(serverDate);
    const currentDate = new Date();
    const getUtsCurrent = currentDate.getTimezoneOffset();
    const localDateUtc = localDate.getTimezoneOffset();
    if (localDateUtc !== Number(changeUtcOffset * -60)) {
      const serverOffsetMillis = 60 * 1000 * Number(changeUtcOffset * -60);
      const sumGetUtccurrent = 60 * 1000 * getUtsCurrent;
      const localOffset = new Date(
        localDate.getTime() - serverOffsetMillis + sumGetUtccurrent
      );
      const defaultHours = localOffset.getHours();
      const hoursChangeUtc = `${
        defaultHours.toString().length === 1
          ? `${"0" + defaultHours}`
          : `${defaultHours}`
      }`;
      const defaultMinutes = localDate.getMinutes();
      const minutes = `${
        defaultMinutes.toString().length === 1
          ? `${"0" + defaultMinutes}`
          : `${defaultMinutes}`
      }`;
      return `${hoursChangeUtc + ":" + minutes}`;
    } else {
      const defaultHours = localDate.getHours();
      const hours = `${
        defaultHours.toString().length === 1
          ? `${"0" + defaultHours}`
          : `${defaultHours}`
      }`;
      const defaultMinutes = localDate.getMinutes();
      const minutes = `${
        defaultMinutes.toString().length === 1
          ? `${"0" + defaultMinutes}`
          : `${defaultMinutes}`
      }`;
      return `${hours + ":" + minutes}`;
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="App">
        {isPreloaderActive && <PreLoaderCallVotingPage />}
        {isLoggedIn &&
          !pathname.includes("/rstpwd") &&
          !pathname.includes("/reset-password") && (
            <Header
              handleLogout={logout}
              userName={userName}
              allEvents={allEvents}
              handleCurrentEvents={handleCurrentEvents}
              handleReloadDetailsPage={handleReloadDetailsPage}
            />
          )}
        <main className="main">
          <div className="main-content _container">
            <Routes>
              <Route
                path="/auth"
                element={
                  <Authorization
                    handleLogin={handleLogin}
                    handleLoginPhone={handleLoginPhone}
                    authError={authError}
                    setAuthError={setAuthError}
                    preLoaderBtn={preLoaderAuthBtn}
                    config={config}
                    requestHelper={requestHelper}
                    setLoggedIn={setLoggedIn}
                    addCurrentUser={addCurrentUser}
                    createUserName={createUserName}
                    setOffset={setOffset}
                    joinId={joinId}
                    joinEvent={joinEvent}
                    eventWaitingIdByLink={eventWaitingIdByLink}
                    eventQuestionsIdByLink={eventQuestionsIdByLink}
                    handleCurrentEvents={handleCurrentEvents}
                    eventResultIdByLink={eventResultIdByLink}
                    showEventResult={showEventResult}
                    subscribeToNewEvents={subscribeToNewEvents}
                  />
                }
              />
              <Route
                path="/forget"
                element={
                  <AuthorizationForgetPassword requestHelper={requestHelper} />
                }
              />
              <Route
                path="/rstpwd"
                element={
                  <AuthorizationSetPassword requestHelper={requestHelper} />
                }
              />
              <Route
                path="/reset-password"
                element={
                  <AuthorizationSetPassword requestHelper={requestHelper} />
                }
              />
              <Route
                exact
                path="/registration"
                element={
                  <Registration
                    requestHelper={requestHelper}
                    handleRegister={handleRegister}
                    handlePolicyAccept={handlePolicyAccept}
                    isPolicyAccept={isPolicyAccept}
                    modalActive={modalActive}
                    emailErrorMessage={emailErrorMessage}
                    setEmailErrorMessage={setEmailErrorMessage}
                    changeBorderInputEmail={changeBorderInputEmail}
                    hideRegisterModal={hideRegisterModal}
                    hideRegForm={hideRegForm}
                    hideRegEmailErrors={hideRegEmailErrors}
                    preLoaderReg={preLoaderRegBtn}
                    config={config}
                  />
                }
              />
              {/* <Route
                path="/registration/:token"
                element={
                  <Registration
                    handleRegister={handleRegister}
                    handlePolicyAccept={handlePolicyAccept}
                    isPolicyAccept={isPolicyAccept}
                    modalActive={modalActive}
                    emailErrorMessage={emailErrorMessage}
                    changeBorderInputEmail={changeBorderInputEmail}
                    hideRegisterModal={hideRegisterModal}
                    hideRegForm={hideRegForm}
                    hideRegEmailErrors={hideRegEmailErrors}
                    preLoaderReg={preLoaderRegBtn}
                    config={config}
                  />
                }
              /> */}
              <Route
                exact
                path="/"
                element={
                  <MainPage
                    allEvents={allEvents}
                    requestHelper={requestHelper}
                    handleCurrentEvents={handleCurrentEvents}
                    toggleEventRegistration={toggleEventRegistration}
                    showEventResult={showEventResult}
                    formatDate={formatDate}
                    formatTime={formatTime}
                    utcOffset={utcOffset}
                    isLoggedIn={isLoggedIn}
                    statsReload={statsReload}
                    setStatsReload={setStatsReload}
                  />
                }
              />
              <Route
                exact
                path="/call-voting-page"
                element={
                  <CallVotingPage
                    requestHelper={requestHelper}
                    handleCurrentEvents={handleCurrentEvents}
                    handleReloadPage={handleReloadPage}
                    isReloadPage={isReloadPage}
                    isSignedEventId={isSignedEventId}
                  />
                }
              />
              <Route
                exact
                path="/my-profile"
                element={
                  <MyProfilePage
                    requestHelper={requestHelper}
                    utcOffset={utcOffset}
                    allEvents={allEvents}
                    createUserName={createUserName}
                    setOffset={setOffset}
                    handleLogout={logout}
                    formatTime={formatTime}
                    formatDate={formatDate}
                    addCurrentUser={addCurrentUser}
                  />
                }
              />
              <Route
                path={`/details-vote/:id`}
                element={
                  <DetailsVotesPage
                    requestHelper={requestHelper}
                    handleCurrentEvents={handleCurrentEvents}
                    toggleEventRegistration={toggleEventRegistration}
                    showEventResult={showEventResult}
                    isResultTabOpen={isResultTabOpen}
                    formatDate={formatDate}
                    formatTime={formatTime}
                    utcOffset={utcOffset}
                    handleResultTabOpen={handleResultTabOpen}
                    isReloadDetailsPage={isReloadDetailsPage}
                    handleReloadDetailsPage={handleReloadDetailsPage}
                    handleReloadPage={handleReloadPage}
                    isReloadPage={isReloadPage}
                    activatePreloader={activatePreloader}
                    disactivatePreloader={disactivatePreloader}
                    isSignedEventId={isSignedEventId}
                    loginByCode={loginByCode}
                  />
                }
              />
              <Route
                exact
                path="/votes-page"
                element={
                  <VotesPage
                    allEvents={allEvents}
                    handleCurrentEvents={handleCurrentEvents}
                    toggleEventRegistration={toggleEventRegistration}
                    showEventResult={showEventResult}
                    formatDate={formatDate}
                    formatTime={formatTime}
                    utcOffset={utcOffset}
                  />
                }
              />
              <Route
                path={"*"}
                element={<NoMatchRoutes isLoggedIn={isLoggedIn} />}
              />
            </Routes>
            <VotesPageSuccessRegLaterModal
              isActive={isSuccessModalActive}
              handleShowSuccessModal={handleShowSuccessModal}
              successModalText={successModalText}
              newEventId={newEventId}
            />
          </div>
        </main>
        {isLoggedIn && !pathname.includes("/rstpwd") && (
          <Footer
            utc={utcOffset}
            // setOffset={setOffset}
            requestHelper={requestHelper}
            config={config}
          />
        )}
      </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
