import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Registration.scss";
import logo from "../../img/Auth_logo_crypto_veche.svg";

import show_pass_icon from "../../img/Auth_show_pass_icon.svg";
import hidden_pass_icon from "../../img/Auth_hidden_pass.svg";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import { useNavigate } from "react-router-dom";
import optionRow from "../../img/INPUT-ICONS-24-ARROW.svg";
import timeZone from "../../utils/TimeZoneData/TimeZoneRu.json";
import { Validation } from "../../utils/Validation";
import row_icon_title from "../../img/Registration_row_icon.svg";
import RegistrationPasswordRequireModal from "./RegistrationPasswordRequireModal/RegistrationPasswordRequireModal";
import * as Auth from "../../Api/Auth";
import { registrationUserByToken } from "../../Api/Auth";
import { useAuthByPhone } from "../../hooks/useAuthByPhone";
const Registration = (props) => {
  const {
    requestHelper,
    handleRegister,
    handlePolicyAccept,
    isPolicyAccept,
    modalActive,
    emailErrorMessage,
    setEmailErrorMessage,
    changeBorderInputEmail,
    hideRegisterModal,
    hideRegForm,
    hideRegEmailErrors,
    preLoaderReg,
    config,
  } = props;

  const CodeType = {
    email: "EMAIL",
    phone: "PHONE",
  };

  const { pathname } = useLocation();

  const firstName = Validation();
  const secondName = Validation();
  const lastName = Validation();
  const email = Validation();
  const phone = Validation();
  const password = Validation();
  const repeatPassword = Validation();

  const [errorPassReg, setErrorPassReg] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [errorPhoneCode, setErrorPhoneCode] = useState("");
  const [errorRegBtnSubmit, setErrorRegBtnSubmit] = useState("");
  const [selectDisactiveEmailPhone, setSelectDisactiveEmailPhone] =
    useState(false);

  const [changeTypePass, setChangeTypePass] = useState("password");
  const [changeTypeRepeatPass, setChangeTypeRepeatPass] = useState("password");
  const [changeBorderInputLastName, setChangeBorderInputLastName] = useState(
    "_input-border-black-reg-page"
  );
  const [changeBorderInputFirstName, setChangeBorderInputFirstName] = useState(
    "_input-border-black-reg-page"
  );
  const [changeBorderInputPass, setChangeBorderInputPass] = useState(
    "_input-border-black-reg-page"
  );
  const [timeZoneLocation, setTimeZoneLocation] = useState(
    "(UTC+3) Россия - Москва - московское время"
  );
  const [timeZoneValue, setTimeZoneValue] = useState(3);
  const [isTimeZoneOptionsOpen, setTimeZoneOptionsOpen] = useState(false);
  const [showHideElem, setShowHideElem] = useState(false);
  const [passwordRequireModalActive, setPasswordRequireModalActive] =
    useState(false);
  const [firsPageError, setFirsPageError] = useState("");
  const [changeRegPhone, setChangeRegPhone] = useState(false);
  const [changeRegEmail, setChangeRegEmail] = useState(true);

  const [token, setToken] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userPhoneCode, setUserPhoneCode] = useState("");
  const [userFields, setUserFields] = useState([]);
  const [isRegistrationByToken, setRegistrationByToken] = useState(false);
  const [activeConfirmPhoneBtn, setActiveConfirmPhoneBtn] = useState(true);
  // const [activeSendCodeBtn, setActiveSendCodeBtn] = useState(false);
  const [activeSMSBlock, setActiveSMSBlock] = useState(false);
  // const [showMessageSMS, setShowMessageSMS] = useState(false);
  const [disactiveInputPhone, setDisactiveInputPhone] = useState(false);

  const linkButtonBackPage = useNavigate();

  function getChangeSelectPhoneEmail() {
    if (changeRegEmail) {
      setChangeRegEmail(false);
      setChangeRegPhone(true);
      // setEmailErrorMessage('')
      setErrorPhoneCode("");
      setErrorRegBtnSubmit("");
    }

    if (changeRegPhone === true) {
      setChangeRegEmail(true);
      setChangeRegPhone(false);
      setErrorPhoneCode("");
      setErrorRegBtnSubmit("");
      // setEmailErrorMessage('')
    }
  }

  useEffect(() => {
    const url = window.location.href.split("/");
    if (url[4]) {
      Auth.getUserByToken(url[4])
        .then((user) => {
          if (user.token !== undefined) {
            setToken(user.token);
            setUserFields(user.fields);
            setUserEmail(user.email);
            setUserPhone(user.phone);
            setRegistrationByToken(true);
            setDisactiveInputPhone(true);
          } else {
            setRegistrationByToken(false);
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
    } else {
      setToken("");
      setUserFields([]);
      setUserEmail("");
      setUserPhone("");
      setRegistrationByToken(false);
    }
  }, [pathname]);

  function onSelectTimeZoneClick(location) {
    setTimeZoneValue(location.VALUE);
    setTimeZoneLocation(location.LABEL);
  }

  function handleTimeZoneOptionsOpen() {
    if (isTimeZoneOptionsOpen) {
      setTimeZoneOptionsOpen(false);
    } else {
      setTimeZoneOptionsOpen(true);
    }
  }
  const showHiddenPass = () => {
    if (changeTypePass === "password") {
      setChangeTypePass("text");
    } else {
      setChangeTypePass("password");
    }
  };

  const showHiddenRepeatPass = () => {
    if (changeTypeRepeatPass === "password") {
      setChangeTypeRepeatPass("text");
    } else {
      setChangeTypeRepeatPass("password");
    }
  };

  const phoneCodeHandler = (e) => {
    const nameRegExp = /[^0-9]/;
    if (!nameRegExp.test(e.target.value) || e.target.value === "") {
      setUserPhoneCode(e.target.value);
    } else {
      console.log("только цифры");
    }
  };

  function onConfirmPhoneNumber(e) {
    e.preventDefault();
    const phoneNumberRegExp = /^(7)[0-9]{10}/;
    const registerData = {
      email: email.value,
      password: password.value,
      firstName: firstName.value,
      secondName: secondName.value,
      lastName: lastName.value,
      utcOffset: timeZoneValue,
      phone: phone.value,
    };
    if (
      activeConfirmPhoneBtn === true &&
      phone.isValid === true &&
      phoneNumberRegExp.test(phone.value) !== false
    ) {
      Auth.getPhoneCode(registerData)
        .then((res) => {
          if (
            res.text === `User with phone number ${phone.value} already exist`
          ) {
            setErrorPhoneCode("Номер уже существует");
            setErrorRegBtnSubmit("");
            setEmailErrorMessage("");
          } else {
            setErrorRegBtnSubmit("");
            setDisactiveInputPhone(true);
            setActiveConfirmPhoneBtn(false);
            setActiveSMSBlock(true);
            setErrorPhoneCode("");
            setSelectDisactiveEmailPhone(true);
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
    } else {
      setErrorPhoneCode("Неверный формат номера");
    }
  }

  function onConfirmEmail(e) {
    e.preventDefault();

    if (email.isValid) {
      
      // setErrorRegBtnSubmit("");
      // setDisactiveInputPhone(true);
      // setActiveConfirmPhoneBtn(false);
      // setActiveSMSBlock(true);
      // setErrorPhoneCode("");
      // setSelectDisactiveEmailPhone(true);
      //return;
      
      Auth.getCode(CodeType.email, email.value)
        .then((res) => {
          console.log(res);
          if (
            (res.status === "failure" && res.text === "Too early refreshing") ||
            res.text === "Max code generating requests reached" || res.status === 429
          ) {
            setErrorPhoneCode("Превышен лимит запросов попробуйте ещё раз");
            setErrorRegBtnSubmit("");
            setEmailErrorMessage("");
          } else {
            setErrorRegBtnSubmit("");
            setDisactiveInputPhone(true);
            setActiveConfirmPhoneBtn(false);
            setActiveSMSBlock(true);
            setErrorPhoneCode("");
            setSelectDisactiveEmailPhone(true);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
          //throw new Error(err?.message || "Неизвестная ошибка");
        });
    } else {
      setErrorPhoneCode("Неверный формат email");
      setActiveSMSBlock(false);
    }
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const nameRegExp = /^([а-яё\s-]+|[a-z\s-]+)$/i;
    const passRegExp =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*,.:;+<>{}?\\[\]/_-]{8,64}$/;
    const phoneNumberRegExp = /^(7)[0-9]{10}/;
    if (lastName.value === "") {
      setChangeBorderInputLastName("_input-border-red");
      setLastNameError("Необходимо заполнить");
      setChangeBorderInputFirstName("_input-border-black-reg-page");
      setFirstNameError("");
      setErrorPassReg("");
      setChangeBorderInputPass("_input-border-black-reg-page");
      setPasswordRequireModalActive(false);
      hideRegEmailErrors();
      setFirsPageError("Ошибки на первой странице");
    } else if (nameRegExp.test(lastName.value) === false) {
      setChangeBorderInputLastName("_input-border-red");
      setLastNameError("Неверный формат");
      setChangeBorderInputFirstName("_input-border-black-reg-page");
      setFirstNameError("");
      setErrorPassReg("");
      setChangeBorderInputPass("_input-border-black-reg-page");
      setPasswordRequireModalActive(false);
      hideRegEmailErrors();
      setFirsPageError("Ошибки на первой странице");
    } else if (firstName.value === "") {
      setChangeBorderInputFirstName("_input-border-red");
      setFirstNameError("Необходимо заполнить");
      setChangeBorderInputLastName("_input-border-black-reg-page");
      setLastNameError("");
      setErrorPassReg("");
      setChangeBorderInputPass("_input-border-black-reg-page");
      setPasswordRequireModalActive(false);
      hideRegEmailErrors();
      setFirsPageError("Ошибки на первой странице");
    } else if (nameRegExp.test(firstName.value) === false) {
      setChangeBorderInputFirstName("_input-border-red");
      setFirstNameError("Неверный формат");
      setChangeBorderInputLastName("_input-border-black-reg-page");
      setLastNameError("");
      setErrorPassReg("");
      setChangeBorderInputPass("_input-border-black-reg-page");
      setPasswordRequireModalActive(false);
      hideRegEmailErrors();
      setFirsPageError("Ошибки на первой странице");
    } else if (password.value === "" || repeatPassword.value === "") {
      setErrorPassReg("Необходимо заполнить все поля");
      setPasswordRequireModalActive(false);
      setChangeBorderInputPass("_input-border-red");
      setChangeBorderInputLastName("_input-border-black-reg-page");
      setLastNameError("");
      setChangeBorderInputFirstName("_input-border-black-reg-page");
      setFirstNameError("");
      hideRegEmailErrors();
      setFirsPageError("");
    } else if (password.value !== repeatPassword.value) {
      setErrorPassReg("Пароли не совпадают");
      setPasswordRequireModalActive(false);
      setChangeBorderInputPass("_input-border-red");
      setChangeBorderInputLastName("_input-border-black-reg-page");
      setLastNameError("");
      setChangeBorderInputFirstName("_input-border-black-reg-page");
      setFirstNameError("");
      hideRegEmailErrors();
      setFirsPageError("");
    } else if (passRegExp.test(password.value) === false) {
      setPasswordRequireModalActive(true);
      setChangeBorderInputPass("_input-border-red");
      setErrorPassReg("");
      setChangeBorderInputLastName("_input-border-black-reg-page");
      setLastNameError("");
      setChangeBorderInputFirstName("_input-border-black-reg-page");
      setFirstNameError("");
      hideRegEmailErrors();
      setFirsPageError("");
    } else {
      if (isRegistrationByToken) {
        handleRegister({
          registrationType: CodeType.email,
          password: password.value,
          firstName: firstName.value,
          secondName: secondName.value,
          lastName: lastName.value,
          utcOffset: timeZoneValue,
          userFields: userFields,
          token: token,
          isRegistrationByToken: true,
        });
      } else {
        if (
          changeRegEmail === true &&
          userPhoneCode !== "" &&
          activeConfirmPhoneBtn === false
        ) {
          handleRegister({
            registrationType: CodeType.email,
            email: email.value,
            password: password.value,
            firstName: firstName.value,
            secondName: secondName.value,
            lastName: lastName.value,
            utcOffset: timeZoneValue,
            phone: "",
            confirmationCode: userPhoneCode,
            isRegistrationByToken: false,
          });
        } else if (
          changeRegPhone === true &&
          userPhoneCode !== "" &&
          activeConfirmPhoneBtn === false
        ) {
          handleRegister({
            registrationType: CodeType.phone,
            password: password.value,
            firstName: firstName.value,
            secondName: secondName.value,
            lastName: lastName.value,
            utcOffset: timeZoneValue,
            phone: phone.value,
            confirmationCode: userPhoneCode,
            isRegistrationByToken: false,
          });
          setErrorRegBtnSubmit("");
        } else {
          setErrorRegBtnSubmit(
            `Необходимо подтвердить ${changeRegEmail ? "email" : "телефон"}`
          );
        }
      }
      setErrorPassReg("");
      setChangeBorderInputPass("_input-border-black-reg-page");
      setPasswordRequireModalActive(false);
      setChangeBorderInputFirstName("_input-border-black-reg-page");
      setFirstNameError("");
      setChangeBorderInputLastName("_input-border-black-reg-page");
      setLastNameError("");
      setFirsPageError("");
    }
  }

  function onDisactiveTimeOut() {
    if (disactiveInputPhone === true) {
      setDisactiveInputPhone(false);
      setSelectDisactiveEmailPhone(false);
      setActiveConfirmPhoneBtn(true);
      setActiveSMSBlock(false);
      setEmailErrorMessage("");
    }
  }

  function mobileShowElem() {
    setShowHideElem(true);
  }

  function mobileHideElem() {
    setShowHideElem(false);
  }

  return (
    <div className={"wrapper-auth"}>
      <div className={"container-auth"}>
        <div className={"main-block"}>
          <div className={"main-block__reg"}>
            <div
              className={
                "reg-block-logotype__logo _modificator-reg-block-logotype__logo-top"
              }
            >
              <img className="reg__logo-main" src={logo} alt="Логотип" />
            </div>
            <div className={"reg-block-logotype__title"}>
              Система электронных голосований
            </div>
            <div className={"reg-block-logotype__button"}>
              <span>Уже есть аккаунт?</span>
              <button onClick={() => linkButtonBackPage("/auth")}>Войти</button>
            </div>
          </div>
          <form
            className={
              hideRegForm ? "main-block__auth active" : "main-block__auth"
            }
            onSubmit={handleSubmit}
          >
            <div className={"reg-form__title"}>
              <img
                onClick={() => {
                  mobileHideElem();
                }}
                alt={"стрелочка ссылка"}
                className={
                  showHideElem
                    ? "reg-form__title-row-icon active"
                    : "reg-form__title-row-icon"
                }
                src={row_icon_title}
              />
              <h3>Регистрация</h3>
              {/*<div><span>РУС</span><span>ENG</span></div>*/}
            </div>
            <div
              className={
                showHideElem
                  ? "reg-form__username active"
                  : "reg-form__username"
              }
            >
              <div className={"username-forms"}>
                <span>
                  Фамилия
                  <span className="reg-form__time-zone-heading_span">*</span>
                </span>
                <input
                  type="text"
                  className={changeBorderInputLastName}
                  name="lastNameRegister"
                  value={lastName.value}
                  onChange={lastName.onChange}
                />
                <span className="reg-form__input-error">{lastNameError}</span>
              </div>
              <div className={"username-forms"}>
                <span>
                  Имя
                  <span className="reg-form__time-zone-heading_span">*</span>
                </span>
                <input
                  type="text"
                  className={changeBorderInputFirstName}
                  name="firstNameRegister"
                  value={firstName.value}
                  onChange={firstName.onChange}
                />
                <span className="reg-form__input-error">{firstNameError}</span>
              </div>
              <div className={"username-forms"}>
                <span>Отчество</span>
                <input
                  type="text"
                  className="_input-border-black-reg-page"
                  name="secondNameRegister"
                  value={secondName.value}
                  onChange={secondName.onChange}
                />
              </div>
            </div>
            <div
              className={
                showHideElem
                  ? "reg-form__e-mail active"
                  : "reg-form__e-mail _reg-block-show"
              }
            >
              {isRegistrationByToken === false ? (
                <div className="reg-form__select-email-phone-container">
                  Выберите тип регистрации по :
                  <select
                    disabled={selectDisactiveEmailPhone}
                    onChange={() => getChangeSelectPhoneEmail()}
                    className="reg-form__select-email-phone"
                  >
                    <option
                      value={changeRegEmail}
                      className="reg-form__select-email"
                    >
                      E-mail
                    </option>
                    <option
                      value={changeRegPhone}
                      className="reg-form__select-phone"
                    >
                      Телефону
                    </option>
                  </select>
                </div>
              ) : null}
              {isRegistrationByToken === true ? (
                <label className="reg-form__label-select">
                  {userPhone !== undefined ? "Телефон" : "Email"}
                </label>
              ) : null}
              {isRegistrationByToken ? (
                <input
                  type={"email"}
                  className={changeBorderInputEmail}
                  value={userPhone !== undefined ? userPhone : userEmail}
                  readOnly
                />
              ) : (
                <>
                  {changeRegEmail ? (
                    <div className="reg-form__input-phone-block">
                      <input
                        type="email"
                        className={changeBorderInputEmail}
                        id="register-email-input"
                        name="emailRegister"
                        placeholder={"user@user.com"}
                        minLength="5"
                        maxLength="45"
                        required
                        disabled={disactiveInputPhone}
                        value={email.value}
                        onChange={email.onChange}
                      />
                      {activeConfirmPhoneBtn ? (
                        <button
                          type="button"
                          onClick={(e) => onConfirmEmail(e)}
                          className={"reg-form__input-phone-block-btn"}
                        >
                          {changeRegEmail
                            ? "Подтвердить email"
                            : "Подтвердить номер"}
                        </button>
                      ) : null}
                      <input
                        className={
                          activeSMSBlock
                            ? "reg-form__input-phone-block-confirm-sms"
                            : "reg-form__input-phone-block-confirm-sms hidden"
                        }
                        placeholder={"Введите код"}
                        minLength={changeRegEmail ? "6" : "4"}
                        maxLength={changeRegEmail ? "6" : "4"}
                        value={userPhoneCode}
                        onChange={(e) => phoneCodeHandler(e)}
                      />
                      {activeSMSBlock ? (
                        <div
                          className="auth__form-disactive-sms-code"
                          onClick={onDisactiveTimeOut}
                        >
                          Повторно получить код
                        </div>
                      ) : null}
                      <div className="reg-form__input-phone-block-error">
                        {errorPhoneCode}
                      </div>
                    </div>
                  ) : null}
                  {changeRegPhone ? (
                    <div className="reg-form__input-phone-block">
                      <input
                        type="text"
                        className={changeBorderInputEmail}
                        id="register-email-input"
                        name="emailRegister"
                        placeholder={"7xxxxxxxxxx"}
                        minLength="11"
                        maxLength="11"
                        required
                        value={phone.value}
                        onChange={phone.onChange}
                        disabled={disactiveInputPhone}
                      />
                      {activeConfirmPhoneBtn ? (
                        <button
                          onClick={(e) => onConfirmPhoneNumber(e)}
                          className={"reg-form__input-phone-block-btn"}
                        >
                          Подтвердить номер
                        </button>
                      ) : null}
                      {/*{activeSendCodeBtn ? <button onClick={(e) => sendMessageCode(e)} className={"reg-form__input-phone-block-btn"}>Отправить код</button>: null}*/}
                      <input
                        className={
                          activeSMSBlock
                            ? "reg-form__input-phone-block-confirm-sms"
                            : "reg-form__input-phone-block-confirm-sms hidden"
                        }
                        placeholder={"Код из SMS"}
                        minLength="4"
                        maxLength="4"
                        value={userPhoneCode}
                        onChange={(e) => phoneCodeHandler(e)}
                      />
                      {activeSMSBlock ? (
                        <div
                          className="auth__form-disactive-sms-code"
                          onClick={onDisactiveTimeOut}
                        >
                          Повторно получить код
                        </div>
                      ) : null}
                      <div className="reg-form__input-phone-block-error">
                        {errorPhoneCode}
                      </div>
                      {/*<div className={showMessageSMS ? "reg-form__confirm-sms-message" : "reg-form__confirm-sms-message hidden"}>Код отправлен</div>*/}
                    </div>
                  ) : null}
                  <div
                    id="register-email-input-error"
                    className="reg-block__error-message"
                  >
                    {emailErrorMessage}
                  </div>
                </>
              )}
            </div>
            <div
              className={
                showHideElem
                  ? "reg-form__password active"
                  : "reg-form__password _reg-block-show"
              }
            >
              <div className={"password-form"}>
                <img
                  alt={"иконка показать пароль"}
                  className={"reg-form__hidden-pass-icon"}
                  src={
                    changeTypePass === "password"
                      ? show_pass_icon
                      : hidden_pass_icon
                  }
                  onClick={showHiddenPass}
                />
                <span>
                  Придумайте пароль{" "}
                  <span className="reg-form__time-zone-heading_span">*</span>
                </span>
                <input
                  type={changeTypePass}
                  className={changeBorderInputPass}
                  name="passwordRegister"
                  value={password.value}
                  onChange={password.onChange}
                  autoComplete="new-password"
                />
              </div>
              <div className={"password-form"}>
                <img
                  alt={"иконка скрыть пароль"}
                  className={"reg-form__hidden-pass-icon"}
                  src={
                    changeTypeRepeatPass === "password"
                      ? show_pass_icon
                      : hidden_pass_icon
                  }
                  onClick={showHiddenRepeatPass}
                />
                <span>
                  Повторите пароль{" "}
                  <span className="reg-form__time-zone-heading_span">*</span>
                </span>
                <input
                  type={changeTypeRepeatPass}
                  className={changeBorderInputPass}
                  name="repeatPasswordRegister"
                  value={repeatPassword.value}
                  onChange={repeatPassword.onChange}
                />
              </div>
              <div className={"reg-block__error-message"}>{errorPassReg}</div>
              <RegistrationPasswordRequireModal
                active={passwordRequireModalActive}
                setActive={setPasswordRequireModalActive}
              />
            </div>
            <div
              className={
                showHideElem
                  ? "reg-form__time-zone-main-container active"
                  : "reg-form__time-zone-main-container"
              }
            >
              <p className="reg-form__time-zone-heading">
                Выберите часовой пояс{" "}
                <span className="reg-form__time-zone-heading_span">*</span>
              </p>
              <div
                className="reg-form__time-zone-select-container"
                onClick={handleTimeZoneOptionsOpen}
              >
                <p className="reg-form__time-zone-select-value">
                  {timeZoneLocation}
                </p>
                <img
                  className="reg-form__time-zone-select-arrow"
                  src={optionRow}
                  alt="Стрелочка открытия меню"
                />
                {isTimeZoneOptionsOpen && (
                  <div className="reg-form__time-zone-options-container">
                    {timeZone.map((location, index) => (
                      <p
                        className="reg-form__time-zone-option"
                        key={index}
                        onClick={() => onSelectTimeZoneClick(location)}
                      >
                        {location.LABEL}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                showHideElem
                  ? "reg-form__checkbox active"
                  : "reg-form__checkbox _reg-block-show"
              }
            >
              <label className={"checkbox_container"}>
                <input
                  type="checkbox"
                  checked={isPolicyAccept}
                  onChange={handlePolicyAccept}
                />
                <span className={"checkmark"} />
              </label>
              {changeRegEmail === true ? (
                <span>
                  Ознакомлен с{" "}
                  <a
                    href={config.confidentiality}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Политикой
                  </a>
                  . Подтверждаю принадлежность мне указанного электронного
                  адреса.
                </span>
              ) : null}
              {changeRegPhone === true ? (
                <span>
                  Ознакомлен с{" "}
                  <a
                    href={config.confidentiality}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Политикой
                  </a>
                  . Подтверждаю принадлежность мне указанного номера телефона.
                </span>
              ) : null}
            </div>
            <div
              className={
                showHideElem
                  ? "reg-form__button active"
                  : "reg-form__button _reg-block-show"
              }
            >
              <span className={"_reg-block-hidden"}>
                Шаг 2 из 2, почти готово
              </span>
              <button
                className={
                  preLoaderReg
                    ? "reg-form__button-btn-reg active"
                    : "reg-form__button-btn-reg"
                }
                type={"submit"}
              >
                {preLoaderReg ? "Загрузка..." : "Зарегистрироваться"}
              </button>
              <p className="reg-form__validate-error">{firsPageError}</p>
              <p className="reg-form__error-submit-phone">
                {errorRegBtnSubmit}
              </p>
            </div>

            {/*-Кнопка для мобильной версии-*/}
            <div
              className={
                showHideElem
                  ? "reg-block__button-next-page active"
                  : "reg-block__button-next-page"
              }
            >
              <span>Шаг 1 из 2</span>
              <button type={"button"} onClick={(e) => mobileShowElem(e)}>
                Продолжить
              </button>
            </div>

            <div className={"reg-block-logotype__button mobile"}>
              <span>Уже есть аккаунт?</span>
              <button onClick={() => linkButtonBackPage("/auth")}>Войти</button>
            </div>
          </form>
        </div>
      </div>
      <RegistrationModal
        active={modalActive}
        hideRegisterModal={hideRegisterModal}
      />
    </div>
  );
};
export default Registration;
