import React, { useState, useRef, useEffect } from "react";
import logo from "../../img/Auth_logo_crypto_veche.svg";

import "./Authorization.scss";
import { useNavigate } from "react-router-dom";
import { Validation } from "../../utils/Validation";
// import { config } from "../../config";

import { useAuthTypes } from "../../hooks/useAuthTypes";
import AuthByEmail from "./AuthByEmail";
import AuthByPhone from "./AuthByPhone";

import * as Auth from "../../Api/Auth";
import * as Events from "../../Api/Events";

const Authorization = (props) => {
  const {
    authError,
    preLoaderBtn,
    config,
    setAuthError,
    requestHelper,
    setLoggedIn,
    addCurrentUser,
    createUserName,
    setOffset,
    joinEvent,
    joinId,
    eventWaitingIdByLink,
    eventQuestionsIdByLink,
    eventResultIdByLink,
    handleCurrentEvents,
    showEventResult,
    subscribeToNewEvents,
  } = props;

  const navigate = useNavigate();

  const [isRememberMe, setIsRememberMe] = useState(true);

  const [activeBtnDisactiveSMS, setActiveBtnDisactiveSMS] = useState(false);

  const [disactiveInputPhone, setDisactiveInputPhone] = useState(false);

  const [activeSMSFieldConfirmBtn, setActiveSMSFieldConfirmBtn] =
    useState(false);
  const authTypes = useAuthTypes();
  const isLoadedTypes = !!Object.keys(authTypes).length;
  const [emailView, setEmailView] = useState(authTypes.email);

  useEffect(() => setEmailView(authTypes.email), [isLoadedTypes]);

  const [changeAuthPhoneEnterSMS, setChangeAuthPhoneEnterSMS] = useState(false);

  const [changeAuthPhoneEnterPass, setChangeAuthPhoneEnterPass] =
    useState(true);

  const [authPhoneCodeError, setAuthPhoneCodeError] = useState("");

  const phoneView = !emailView;

  const activeTypeDisplay = config.type;

  const email = Validation();
  const emailPassword = Validation();
  const emailCode = Validation();

  const phone = Validation();
  const phonePassword = Validation();
  const phoneCode = Validation();

  const [passwordType, setPasswordType] = useState("password");

  const getPassword = () => {
    if (passwordType === "password") {
      return emailView ? emailPassword.value : phonePassword.value;
    }

    if (passwordType === "temporary-code") {
      return emailView ? emailCode.value : phoneCode.value;
    }

    return "";
  };

  const onEnterHandler = async (evt) => {
    evt.preventDefault();

    const loginResponse = await Auth.login({
      username: emailView ? email.value : phone.value,
      password: getPassword(),
      type: `${emailView ? "email" : "phone"}/${passwordType}`,
    });

    if (loginResponse?.status === 400) {
      setAuthError("Пользователь не найден");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status === 401) {
      setAuthError("Неверный логин или пароль");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status) {
      setAuthError(`Ошибка сервера: ${loginResponse.status}`);
      return;
    }

    const userData = await Auth.getMe(loginResponse.accessToken);

    if (loginResponse.accessToken && loginResponse.refreshToken) {
      localStorage.setItem("accessToken", loginResponse.accessToken);
      localStorage.setItem("refreshToken", loginResponse.refreshToken);
    }

    isRememberMe && localStorage.setItem("user", JSON.stringify(userData));
    setLoggedIn(true);
    addCurrentUser(userData);
    createUserName(userData);
    setOffset(userData.utcOffset);

    const subUserId = `${userData.authorities[0].role}${userData.id}`;
    //const subUserId = `${userData.id}`;
    subscribeToNewEvents(subUserId, config);

    console.log("subUserId: ", subUserId);

    if (joinId) {
      joinEvent(joinId);
      navigate("/");
      return;
    }

    if (eventWaitingIdByLink) {
      handleCurrentEvents({ id: eventWaitingIdByLink }, true);
      return;
    }

    if (eventQuestionsIdByLink) {
      const data = { id: eventQuestionsIdByLink };
      requestHelper(Events.getEvent, data)
        .then((res) => {
          // тут вопросик мб не правильная проверка в конце
          handleCurrentEvents(
            data,
            res.status === "ended" ||
              res.status === "quorum_unpresant" ||
              !res.isRegistered
          );
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
      return;
    }

    if (eventResultIdByLink) {
      showEventResult({ id: eventResultIdByLink });
      return;
    }
    // navigate("/");
  };

  return (
    isLoadedTypes && (
      <div className="wrapper-auth">
        <div className="container-auth">
          <div className="main-block">
            <div className="main-block__auth">
              <div className="auth__title">
                <h3>Авторизация</h3>
                {/*<div><span>РУС</span><span>ENG</span></div>*/}
              </div>
              <form className="auth__form">
                {authTypes.emailAndPhone && (
                  <div className="auth-form__select-email-phone-container  form__views">
                    Выполнить вход по:
                    <span
                      onClick={() => setEmailView(true)}
                      className={
                        emailView
                          ? "auth-form__select-email active"
                          : "auth-form__select-email"
                      }
                    >
                      E-mail
                    </span>
                    /
                    <span
                      onClick={() => setEmailView(false)}
                      className={
                        phoneView
                          ? "auth-form__select-phone active"
                          : "auth-form__select-phone"
                      }
                    >
                      Телефону
                    </span>
                  </div>
                )}

                {(emailView || authTypes.emailOnly) && (
                  <AuthByEmail
                    authError={authError}
                    authTypes={authTypes}
                    email={email}
                    emailPassword={emailPassword}
                    emailCode={emailCode}
                    setPasswordType={setPasswordType}
                  />
                )}

                {(!emailView || authTypes.phoneOnly) && (
                  <AuthByPhone
                    authError={authError}
                    setAuthError={setAuthError}
                    authTypes={authTypes}
                    phone={phone}
                    phonePassword={phonePassword}
                    phoneCode={phoneCode}
                    setPasswordType={setPasswordType}
                  />
                )}

                <div className="form__checkbox">
                  <div className="checkbox__link-forget-pass-login-secretary">
                    {activeTypeDisplay && (
                      <span
                        className="link-forget-pass"
                        onClick={() => navigate("/forget")}
                      >
                        Забыли пароль?
                      </span>
                    )}
                    <a
                      href={config.admin_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Войти как секретарь
                    </a>
                  </div>
                  <label className="auth-form__checkbox-container">
                    <div className="checkbox_container">
                      <input
                        type="checkbox"
                        checked={isRememberMe}
                        onChange={() => setIsRememberMe(!isRememberMe)}
                      />
                      <span className="checkmark" />
                    </div>
                    <span className="auth__remember-me-checklabel">
                      Запомнить меня
                    </span>
                  </label>
                </div>
                <div
                  className={
                    activeTypeDisplay ? "form__button" : "form__button-spbu"
                  }
                >
                  <button
                    className={
                      preLoaderBtn
                        ? "form__button-input-btn active"
                        : "form__button-input-btn"
                    }
                    onClick={onEnterHandler}
                    type="submit"
                  >
                    {preLoaderBtn ? "Загрузка..." : "Войти"}
                  </button>
                  <div className="form__error-phone-code">
                    {authPhoneCodeError}
                  </div>
                  {config.enable_esia && (
                    <a
                      className="form__button__link-gosuslugi"
                      href="https://esia.gosuslugi.ru/login/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Войти через ЕСИА
                    </a>
                  )}
                </div>

                {activeTypeDisplay && (
                  <div className="main-block__mobile-link-reg">
                    <span>Ещё нет аккаунта?</span>
                    <span onClick={() => navigate("/registration")}>
                      Зарегистрироваться
                    </span>
                  </div>
                )}
              </form>
            </div>
            <div className="main-block__reg">
              <div className="reg__logo">
                <img className="reg__logo-main" src={logo} alt="Логотип" />
              </div>
              <div className="reg__title">Система электронных голосований</div>
              {activeTypeDisplay && (
                <div className="reg__button">
                  <span>Еще нет аккаунта?</span>
                  <button onClick={() => navigate("/registration")}>
                    Зарегистрироваться
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Authorization;
